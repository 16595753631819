<template>
  <div>
    <div id="map" />
    <v-container fluid>
      <div class="d-flex justify-end">
        <v-btn outlined color="darkblue" @click="showEditDialog = true">Ajouter une organisation</v-btn>
      </div>

      <Table
        :headers="headers"
        :items="items"
        @onDelete="showDeleteDialog"
        @onEdit="openEditDialog"
        :loading="this.isLoading"
        :showExpand="true"
        :expandedList="expandedList"
      />
    </v-container>

    <v-dialog v-model="showEditDialog" width="500">
      <v-card>
        <v-card-title>{{ form.newOrganization ? 'Ajouter une organisation' : "Éditer l'organisation" }}</v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-menu v-model="showMenu" nudge-bottom="40px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="placeDetails"
                  v-model="form.name"
                  outlined
                  dense
                  label="Nom"
                  v-on="on"
                  v-bind="attrs"
                  :rules="[rules.required]"
                />
              </template>

              <v-list style="max-width: 452px" v-if="autocompleteItems">
                <v-list-item v-for="item in autocompleteItems" :key="item.place_id" @click="onSelectPlace(item)">
                  <v-list-item-title>{{ item.description }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-text-field v-model="form.streetName" outlined dense label="Rue" />
            <v-text-field v-model="form.city" outlined dense label="Ville" />
            <v-text-field v-model="form.stateCode" outlined dense label="Ce de province" maxlength="2" />
            <v-text-field v-model="form.countryCode" outlined dense label="Code de pays" maxlength="2" />
            <v-text-field
              v-model="form.longitude"
              outlined
              dense
              label="Longitude"
              type="number"
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="form.latitude"
              outlined
              dense
              label="Latitude"
              type="number"
              :rules="[rules.required]"
            />
             <v-text-field
              v-model="form.zoom"
              outlined
              dense
              label="Zoom"
              type="number"
              :rules="[rules.required]"
            />
            <v-text-field v-model="form.radius" outlined dense label="Rayon" type="number" suffix="m" />
            <v-text-field
              v-model="form.emergencyNumber"
              outlined
              dense
              label="Téléphone d'urgence"
              type="text"
              v-mask="maskNumber(form.emergencyNumber)"
            />
            <v-text-field
              v-model="form.operatorNumber"
              outlined
              dense
              label="Téléphone de l'opérateur"
              type="text"
              v-mask="maskNumber(form.operatorNumber)"
            />
            <v-text-field
              v-model="form.secondaryOperatorNumber"
              outlined
              dense
              label="Téléphone secondaire de l'opérateur (si le principal est occupé)"
              type="text"
              v-mask="maskNumber(form.operatorNumber)"
            />
            <v-file-input
              v-model="form.organizationLogo"
              show-size
              outlined
              dense
              label="Logo de l'organisation"
              accept="image/png"
              :rules="form.organizationLogo ? [rules.pngOnly] : []"
              @change="onFileChange"
            ></v-file-input>
            <v-img :src="imageUrl" :aspect-ratio="1" :width="100" v-if="imageUrl" />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn color="secondary" text @click="onSubmitForm" :loading="isLoading"> Save </v-btn>
          <v-btn color="error" text @click="showEditDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { loadScript, unloadScript } from 'vue-plugin-load-script';
import Table from '@/components/Admin/Table';

export default {
  data: () => ({
    headers: [
      { class: 'caption darkblue--text', text: 'Logo', value: 'orgLogoLink' },
      { class: 'caption darkblue--text', text: 'Nom', value: 'name' },
      { class: 'caption darkblue--text', text: 'Rue', value: 'streetName' },
      { class: 'caption darkblue--text', text: 'Ville', value: 'city' },
      { class: 'caption darkblue--text', text: "Téléphone d'urgence", value: 'emergencyNumber' },
      { class: 'caption darkblue--text', text: "Téléphone de l'operateur", value: 'operatorNumber' },
      {
        class: 'caption darkblue--text',
        text: "Téléphone secondaire de l'opérateur (si le principal est occupé)",
        value: 'secondaryOperatorNumber',
      },
      { text: '', value: 'actions', align: 'center' },
      { text: '', value: 'data-table-expand' },
    ],
    expandedList: [
      { class: 'caption darkblue--text', text: 'Code de province', prop: 'stateCode' },
      { class: 'caption darkblue--text', text: 'Code de pays', prop: 'countryCode' },
      { class: 'caption darkblue--text', text: 'Longitude', prop: 'longitude' },
      { class: 'caption darkblue--text', text: 'Latitude', prop: 'latitude' },
      { class: 'caption darkblue--text', text: 'Zoom', prop: 'zoom' },
      { class: 'caption darkblue--text', text: 'Rayon (m)', prop: 'radius' },
    ],
    showEditDialog: false,
    isValid: true,
    form: {
      name: '',
      streetName: '',
      city: '',
      stateCode: '',
      countryCode: '',
      longitude: '',
      latitude: '',
      zoom: 12,
      radius: '',
      emergencyNumber: '',
      operatorNumber: '',
      newOrganization: true,
      organizationLogo: null,
      orgLogoBase64: '',
    },
    rules: {
      required: (v) => !!v || 'Ce champ est requis',
      pngOnly: (v) => /png/.test(v?.type) || 'Doit être un fichier de type PNG',
    },
    showMenu: false,
    service: null,
    placesService: null,
    autocompleteItems: null,
    imageUrl: '',
  }),
  components: {
    Table,
  },
  mounted() {
    if (!this.$store.state.organizations.isInitialized) {
      this.$store.dispatch('organizations/getOrganizations');
    }

    loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_FIREBASE_API_KEY}&libraries=places`)
      .then(() => {
        this.service = new window.google.maps.places.AutocompleteService();
        this.placesService = new window.google.maps.places.PlacesService(document.getElementById('map'));
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  beforeUnmount() {
    unloadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_FIREBASE_API_KEY}&libraries=places`);
  },
  computed: {
    items() {
      return this.$store.state.organizations.organizations;
    },
    placeName() {
      return this.form.name;
    },
    isLoading() {
      return this.$store.state.organizations.isLoading;
    },
  },
  methods: {
    async showDeleteDialog(payload) {
      const confirm = await this.$root.$confirmDiaglog(
        'Confirmation',
        'Être-vous certain de vouloir effacer cette organisation?',
      );
      if (confirm) {
        await this.$store.dispatch('organizations/deleteOrganization', { payload });
      }
    },
    openEditDialog(item) {
      this.form = { ...item, newOrganization: false };
      if (item.orgLogoBase64) {
        const pathComponents = item.orgLogoLink.split('/');
        const orgLogoName = pathComponents[pathComponents.length - 1];
        this.form.organizationLogo = this.base64toFile(item.orgLogoBase64, orgLogoName);
      }
      this.onFileChange(this.form.organizationLogo);
      this.showEditDialog = true;
    },
    numericOnly(pn) {
      return pn.replace(/\D/g, '');
    },
    async onSubmitForm() {
      if (this.$refs.form.validate()) {
        this.form.emergencyNumber = this.numericOnly(this.form.emergencyNumber);
        this.form.operatorNumber = this.numericOnly(this.form.operatorNumber);
        if (this.form.zoom) {
          this.form.zoom = Number(this.form.zoom);
        }
        if (this.form.organizationLogo) {
          this.form.orgLogoBase64 = await this.getBase64(this.form.organizationLogo);
          const orgName = this.form.name.split(' ').join('_');
          const uploadDate = Date.now();
          const fileType = this.form.organizationLogo.type.split('/')[1];
          this.form.orgLogoName = `${orgName}_${uploadDate}.${fileType}`;
        } else {
          this.form.orgLogoBase64 = '';
        }
        await this.$store.dispatch('organizations/submitOrganization', { payload: this.form });

        this.showEditDialog = false;
      }
    },
    onSelectPlace(item) {
      this.placesService.getDetails({ placeId: item.place_id }, (res, status) => {
        if (status === 'OK') {
          // console.log(res, 'res');
          this.form.name = res.name;
          this.form.latitude = res.geometry.location.lat();
          this.form.longitude = res.geometry.location.lng();

          const countryCode = res.address_components.find((cmp) => cmp.types.includes('country'));
          const stateCode = res.address_components.find((cmp) => cmp.types.includes('administrative_area_level_1'));
          this.form.city = res.address_components.find((cmp) => cmp.types.includes('locality'))?.long_name || null;
          this.form.streetName = res.address_components.find((cmp) => cmp.types.includes('route'))?.long_name || null;
          this.form.countryCode = countryCode?.short_name || null;
          this.form.stateCode = stateCode?.short_name || null;
          this.showMenu = false;
        }
      });
    },
    setPredictions(location) {
      this.service.getPlacePredictions(
        {
          input: location,
        },
        (predictions, status) => {
          if (status === 'OK') this.autocompleteItems = predictions;
        },
      );
    },
    maskNumber(value) {
      let format = '###-###-####';
      if (value?.length === 3) {
        format = '###';
      } else if (value?.length === 7) {
        format = '###-###';
      }
      return format;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    base64toFile(base64, filename) {
      const arr = base64.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) { // eslint-disable-line
        u8arr[n] = bstr.charCodeAt(n);
      }

      const file = new File([u8arr], filename, { type: mime });
      return file;
    },
    onFileChange(file) {
      const reader = new FileReader();
      if (!file) {
        this.imageUrl = '';
        return;
      }

      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
  watch: {
    showEditDialog(val) {
      if (!val) {
        this.form = {
          name: '',
          streetName: '',
          city: '',
          stateCode: '',
          countryCode: '',
          longitude: '',
          latitude: '',
          radius: '',
          emergencyNumber: '',
          operatorNumber: '',
          newOrganization: true,
          organizationLogo: null,
          orgLogoBase64: '',
        };

        this.$refs.form.resetValidation();
      }
    },
    placeName(val) {
      if (val) {
        if (this.$refs.placeDetails && this.$refs.placeDetails.isFocused) this.showMenu = true;
        if (this.showMenu) this.setPredictions(val);
      }
    },
  },
};
</script>
